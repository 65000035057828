













import Vue from "vue";
import TabList from "@/components/TabList.vue";
export default Vue.extend({
  components: {TabList},
  data: () => ({
    tab: {
      selectedTab: 0,
      tabTitle: "实验室人员",
      tabs: [
        { id: 0, text: "教师", path: "/staff/professor" },
        { id: 1, text: "博士生", path: "/staff/doctor" },
        { id: 2, text: "硕士生", path: "/staff/postgraduate" },
        { id: 3, text: "本科生", path: "/staff/undergraduates" },
        { id: 3, text: "毕业学生", path: "/staff/graduated" },
        { id: 4, text: "访问学者", path: "/staff/visitor" },
      ],
    },
  }),
  created() {
    console.log("page", this.$route.name);
    if (this.$route.name == "professor") {
      this.tab.selectedTab = 0;
    } else if (this.$route.name == "doctor") {
      this.tab.selectedTab = 1;
    }else if (this.$route.name == "postgraduate") {
      this.tab.selectedTab = 2;
    }else if (this.$route.name == "undergraduates") {
      this.tab.selectedTab = 3;
    }else if (this.$route.name == "visitor") {
      this.tab.selectedTab = 4;
    }
  },
});
